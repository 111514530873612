<template>
  <b-row class="content-section">
    <div id="main-content" class="main-page col-xs-12 clearfix container">
      <div class="wpb_column vc_column_container vc_col-sm-12">
        <div class="vc_column-inner">
          <div class="wpb_wrapper">
            <div class="wpb_text_column wpb_content_element ">
              <div class="wpb_wrapper">
                <p><strong>General terms and conditions of service</strong></p>
                <p><strong><u>PLEASE READ THE TERMS AND CONDITIONS OF SERVICE CAREFULLY.</u></strong></p>
                <p>&nbsp;</p>
                <p><strong>DISCLAIMER: PLEASE NOTE THAT CASH TRANSACTIONS BETWEEN CLIENT AND ANY STAFF, AGENT OR PERSONS PURPORTEDLY ACTING ON BEHALF OF TURTLE RENT/ CROWNCITY TECHNOLOGIES LTD (‘TURTLE RENT’) IS &nbsp;NOT ALLOWED. TURTLE RENT WILL NOT ACCEPT LIABILITY FOR SUCH TRANSACTIONS OR PAYMENTS.</strong></p>
                <p>&nbsp;</p>
                <ol>
                  <li>Introduction</li>
                </ol>
                <p>These terms and conditions apply to and regulate the provision of credit facilities by EXXTRA &amp; Partner Banks to the borrower herein; and together with the offer letter sets out the terms governing this credit agreement. Your use of the&nbsp;<em>EXXTRA</em> online platform signifies acceptance of all the provisions of the terms and conditions of service; and these may be amended at any time by posting a revised version on&nbsp;<em>turlerent</em>.co . The revised version becomes effective from the time it is posted on the website.</p>
                <p>&nbsp;</p>
                <ol start="2">
                  <li>Eligibility</li>
                </ol>
                <p>By logging in to the&nbsp;<em>exxtra.app</em> website, users signify and warrant that they have the legal capacity to enter into a contract and are of at least 18 years old being the minimum legal age to use the&nbsp;<em>exxtra.app</em> platform.</p>
                <p>&nbsp;</p>
                <ol start="3">
                  <li>Repayment</li>
                </ol>
                <p>Repayment of all credit facilities shall be in the sum and manner stipulated in the repayment schedule issued upon acceptance of the terms and conditions but not later than 180 days from the effective date of this agreement.&nbsp;&nbsp;<strong>PROVIDED THAT&nbsp;</strong> may, upon a 7-day notice to the customer review the manner and term(s) of repayment.</p>
                <p>&nbsp;</p>
                <ol start="4">
                  <li>Mode of payment</li>
                </ol>
                <p>The repayments shall be by any one or all of the following: direct deposit/transfer into a designated account of EXXTRA, personal cheque, standing orders, or through any acceptable electronic channel. All other methods shall be accepted with EXXTRA written consent only&nbsp;<strong>PROVIDED THAT:</strong></p>
                <ol>
                  <li>EXXTRA shall provide the borrower with a detailed and personalized/customized repayment plan upon approval of the loan/credit application;</li>
                  <li>The borrower undertakes to make repayments in accordance with the repayment schedule/plan; and</li>
                  <li>The borrower covenants that non-payment of the whole or any portion of the credit facility/loan as at when due shall constitute a breach which entitles EXXTRA to recover the outstanding loan amount and all incidental fees, charges and costs.</li>
                  <li>Where the Borrower makes a direct payment in advance of due date, EXXTRA shall be at liberty to return to the borrower or destroy all cheques (including post-dated) at the expiration of the loan or on payment of all instalment by the Borrower. Any such refund made to the borrower for duplicate payment may attract refund processing fee.</li>
                  <li>In the event of early liquidation, the borrower undertakes to repay the full outstanding amount, and all incidental accrued charges, costs and fees and a fixed liquidation fee.</li>
                </ol>
                <p>6. Credit reference</p>
                <p>EXXTRA or its authorized agents/representatives shall utilize a dedicated Credit Agency for a credit report on the borrower in the consideration of any application for credit.</p>
                <p>The borrower authorizes EXXTRA’s access to any information as may be available on the borrower shall be provided by GHIPSS or any third party agency or platform engaged by EXXTRA for disbursement and &nbsp;recovery of loan for assessment before disbursement</p>
                <p>The borrower authorizes EXXTRA’s access to any information as may be available on the borrower shall be provided by the Credit Agency. The borrower also agrees that the borrower’s details and the loan application decision may be registered with the Credit Agency.</p>
                <ol start="6">
                  <li>Notices</li>
                </ol>
                <p>The Borrower agrees that EXXTRA may communicate with him by sending notices, messages, alerts and statements in relation to this agreement in the following manner:</p>
                <p>To the most recent physical address EXXTRA holds for the borrower;</p>
                <ol>
                  <li>By delivery to any email address provided by the borrower during the application process;</li>
                  <li>By delivery of an SMS to any mobile telephone number the borrower has provided to EXXTRA.</li>
                  <li>Event of default</li>
                </ol>
                <p>Default in terms of this Agreement occurs if:</p>
                <ol>
                  <li>The borrower fails to make any scheduled repayment in full on or before the payment date in accordance with the repayment plan.</li>
                  <li>Any representation, warranty or assurance made or given by the borrower in connection with the application for the loan or any information or documentation supplied by the borrower is discovered to be materially incorrect; or</li>
                  <li>The borrower does or omits to do anything which may prejudice EXXTRA’s rights in terms of this Agreement or causes EXXTRA to suffer any loss or damage.</li>
                </ol>
                <p>In the event of any default by the borrower subject to the above –</p>
                <ol>
                  <li>EXXTRA shall apply a daily default charge on the amount due until account is made current.</li>
                  <li>EXXTRA reserves the right to notify any member of the general public (including, but not limited to the borrower’s employer) in the event of default or non-repayment by the borrower.</li>
                </ol>
                <p>iii. EXXTRA reserves the right to assign its right, title and interest under the Agreement to an external Collections Agency who will take all reasonable steps to recover any amount due and outstanding.</p>
                <ol>
                  <li>EXXTRA also reserves the right to institute legal proceedings against the defaulting borrower and is under no obligation to inform the borrower before such proceedings commence.</li>
                  <li>The borrower shall be responsible for all legal costs and expenses incurred by EXXTRA in the effort to recover any outstanding loan balance owed by the borrower.</li>
                  <li>EXXTRA reserves the right to recover funds through any other means such as borrowers accout with other financial institution and account of the guarantor</li>
                  <li>General</li>
                  <li>These terms and condition represent the entire Agreement between EXXTRA and the borrower. Amendments to this agreement may be made at any time by EXXTRA subject to notifications to the borrower. Any amendment to this agreement becomes binding on the borrower after 7 days of its notification to the borrower</li>
                  <li>The borrower agrees that in the event this loan application is rejected by EXXTRA, the application documents shall remain in the custody of EXXTRA.</li>
                  <li>The borrower agrees and undertakes that for the period of this Agreement, the borrower shall not close the (borrower’s) specified bank account.</li>
                  <li>This Agreement shall be governed by the laws of the Republic of Ghana and shall be subject to the jurisdiction of the courts of the Republic of Ghana.</li>
                  <li>The failure or refusal of EXXTRA to enforce any right or obligation under this Agreement against the borrower (including its right to insist on the repayment of all sums due on the repayment due date) or grant the borrower an indulgence, shall not be deemed to be a waiver of those rights nor prevent an enforcement of such rights at a later date.</li>
                  <li>EXXTRA reserves the right to transfer or assign its rights and obligations under this Agreement (including its obligation to grant credit to the Borrower or the amount owed under this Agreement) to another person. PROVIDED THAT EXXTRA shall inform the Borrower if such a transfer causes the arrangements for the administration of this Agreement to change.</li>
                  <li>The borrower authorizes and consents to all lawful access, use or disclosure of the borrower’s particulars in the application by EXXTRA which may include but shall not be limited to purposes necessary to promote or sustain the business of EXXTRA; and the borrower waives any claims against EXXTRA arising from any such access, use or disclosure.</li>
                  <li>EXXTRA shall be at liberty to report to the appropriate law enforcement agencies and/or regulatory bodies any false or fraudulent information/documentation submitted by the borrower.</li>
                  <li>EXXTRA reserves the right to review, verify and screen all details, including but not limited to the loan amount applied for by the Borrower and EXXTRA is not bound to grant the credit in the amount or manner applied for by the borrower.</li>
                </ol>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <ol start="9">
                  <li>Declaration by borrower</li>
                  <li>I hereby confirm that I have read, understood and agree to the above terms and conditions. I also authorize EXXTRA to present the repayment instrument issued by me (in favour of EXXTRA) for the repayment of the loan/credit as and when due until the entire amount owed is fully paid .</li>
                </ol>
                <p>&nbsp;</p>
                <ol>
                  <li>I also declare by signing this Agreement and taking credit/drawing on the loan that I shall repay all moneys in accordance with the schedule of repayment as and when due. In the event that I refuse to repay in the terms of the schedule and the credit facility becomes delinquent, EXXTRA shall have the right to report the delinquent loan to the appropriate authorities in line with extant lending regulations.</li>
                  <li>I authorize EXXTRA to set-off my indebtedness from any money standing to my credit in any bank account and from any other financial assets they may be holding for my benefits, or to which they have recourse or access.</li>
                  <li>I covenant and warrant that EXXTRA shall have the power to set-off my indebtedness under this agreement from all such monies and funds standing to my credit/benefit in any or all such account or from any other financial assets belonging to me and in the custody of any bank in Ghana.</li>
                  <li>I hereby waive any right of confidentiality whether arising under common law or statute or in any other manner whatsoever and irrevocably agree that I shall not argue to the contrary before any court of law, tribunal administrative authority or any other body acting in any judicial or quasi-judicial capacity.</li>
                </ol>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-row>
</template>

<script>
import {
  BRow,
} from 'bootstrap-vue'

export default {
  components: {
    BRow
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-public.scss';
</style>
